<template>
  <div>
    <b-modal
      ref="my-modal"
      v-model="IsChangePasswordPopupActiveLocal"
      :title="$t('Change Password')"
      hide-footer
      @hidden="close()"
    >
      <div class="con-exemple-prompt">
        <div>
          <vx-input
            v-model="password.password"
            class="mt-2"
            :label="$t('New Password')"
            :errors="errors.password"
          />
        </div>
        <div>
          <vx-input
            v-model="password.password_confirmation"
            class="mt-2"
            :label="$t('Password Confirmation')"
            :errors="errors.password"
          />
        </div>
        <br>
        <vs-button
          v-if="!GeneratedPassword"
          color="primary"
          type="gradient"
          @click="GeneratePassword"
        >
          {{ $t('Generated Password') }}
        </vs-button>
        <div v-if="GeneratedPassword">
          <vs-input
            id="PasswordToCopy"
            v-model="GeneratedPassword"
            style="width: 100%"
            class="mt-2"
            :label="$t('Generate Password')"
          />

          <br>
          <vs-button
            color="warning"
            type="gradient"
            @click="copy"
          >
            {{ $t('Copy Password') }}
          </vs-button>
          <br>
          <br>
          <vs-checkbox
            v-model="SavePlacePassword"
            vs-value="email"
            class="mr-4 mb-2"
          >
            {{ $t('I have copied this password in a safe place.') }}
          </vs-checkbox>
        </div>
        <br>
        <br>
        <div>
          <hr>
        </div>
        <div
          class="d-flex justify-content-end"
          @click="changePassword"
        >
          <b-button variant="gradient-success">
            {{ $t('changePassword') }}
          </b-button>
        </div>
        <br>
      </div>
    </b-modal>
  </div>
</template>

<script>
/* eslint-disable */
export default {
    name: "ChangePasswordComponent",
  props:['value','data'],
    data: () => ({
      password:{
      "password_confirmation": "",
      "password": "",
      },
      SavePlacePassword: true,
      GeneratedPassword: '',
      UserID:'',
      errors:[],
    }),
    computed: {
      IsChangePasswordPopupActiveLocal: {
        get() {
          return this.value
        },
        set(val) {
          this.$emit('input', val)
        },
      },
    },
    methods: {
      close() {
        this.IsChangePasswordPopupActiveLocal = false
        this.SavePlacePassword = true;
        this.errors=[]
        this.password.password = '';
        this.password.password_confirmation = '';
        this.GeneratedPassword = '';
      },
      copy() {
        let PasswordToCopy = document.querySelector('#PasswordToCopy')
        PasswordToCopy.removeAttribute("disabled")
        PasswordToCopy.select()
        this.SavePlacePassword = true;
        document.execCommand('copy');
        PasswordToCopy.setAttribute('disabled', 'disabled')
        this.$vs.notify({
          color: 'success',
          title: this.$t('Successful'),
          text: this.$t('Password has been copied successfully'),
          iconPack: 'feather',
          icon: 'icon-check'
        })
      },
      GeneratePassword() {
        this.GeneratedPassword = Math.random().toString(36).slice(-8) + Math.random().toString(36).slice(-8)
        this.password.password = this.GeneratedPassword
        this.password.password_confirmation = this.GeneratedPassword
        this.SavePlacePassword = false
      },
      changePassword() {
        this.errors=[]
        this.UserID = this.data.id;
        if (this.SavePlacePassword === false){
          this.IsChangePasswordPopupActiveLocal = true
          this.$vs.notify({
            color: 'danger',
            title: this.$t('Error'),
            text: this.$t('Please check "I have copied this password in a safe place."'),
            iconPack: 'feather',
            icon: 'icon-check'

          })
        }
        else  {
          this.$store.dispatch('user/ChangePassword', {'id':this.data.id,'password':this.password}).then(response => {
            this.close();
          }).catch((error) => {
            if(error.response.data.errors)
              this.errors = error.response.data.errors
          })
        }
      },
    },
}
</script>

<style scoped>

</style>
