<template>
  <div>
    <b-modal
      v-model="IsStatusPopupActiveLocal"
      :title="$t('Change User Status')"
      :ok-variant="data.status==='Active'?'danger':'success'"
      :accept-text="data.status===$t('Active')?$t('Block'):$t('Activate')"
      cancel-variant="outline-secondary"
      :cancel-title="$t('Cancel')"
      :ok-title="$t('OK')"
      @cancel="close"
      @ok="changeUserStatus"
      @hidden="close"
    >
      <div>{{ $t('Are you sure you want to change the user status') }}</div>
      <br>
      <div
        v-if="data.status==='Blocked'"
        class="con-exemple-prompt"
      >
        <h6>{{ $t('he was blocked because') }}</h6>
        <br>
        <vs-chip color="danger">
          {{ $t(data.blocked_reason) }}
        </vs-chip>
        <br>
      </div>
      <div
        v-if="data.status==='Active'"
        class="con-exemple-prompt"
      >
        <vx-input
          v-model="UserData.blocked_reason"
          :label="$t('Reason of blocking')"
        />
      </div>
    </b-modal>
  </div>
</template>

<script>
/* eslint-disable */
export default {
    name: "ChangeStatusComponent",
  props:['value','data'],
    data: () => ({
      StatusOption: ['Active', 'Blocked'],
      UserData: {
        "id":"",
        "status": "",
        "blocked_reason": "",
      },
    }),
    computed: {
      IsStatusPopupActiveLocal: {
        get() {
          return this.value
        },
        set(val) {
          this.$emit('input', val)
        },
      },
    },
    methods: {
      close() {
      this.IsStatusPopupActiveLocal = false
      },
      changeUserStatus() {

        if (this.data.status === "Active") {
          this.UserData.status = 'Blocked';
          this.UserData.id = this.data.id;
        } else if (this.data.status === "Deactivated" || this.data.status === "Blocked") {
          this.UserData.status = 'Active';
          this.UserData.id = this.data.id;
        }

        this.$store.dispatch('user/EditUserStatus',{'userdata':this.UserData}).then(() => {
          if(this.data.user_type==='SuperAdmin')
          this.$store.dispatch('user/GetData')
          else if(this.data.user_type==='Person'){
            this.$store.dispatch('employee/GetData')
          }
          else {
            this.$store.dispatch('company/GetData')
          }
        })
      },
    },
}
</script>

<style scoped>

</style>
