<template>
  <div>
    <b-card
      class="mt-10"
      :title="$t('Explanation')"
    >
      <vs-row>
        <vs-col
          class="mb-3"
          vs-w="3"
          vs-sm="3"
          vs-xs="6"
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
        >
          <vx-badge
            pack="far"
            icon="calendar-plus"
            color="light-success"
            icon-color-bg="success"
            icon-color="null"
          >
            {{ $t('Created At') }}
          </vx-badge>
        </vs-col>
        <vs-col
          class="mb-3"
          vs-w="3"
          vs-sm="3"
          vs-xs="6"
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
        >
          <vx-badge
            pack="fas"
            icon="calendar-alt"
            color="light-warning"
            icon-color-bg="warning"
            icon-color="null"
          >
            {{ $t('Updated At') }}
          </vx-badge>
        </vs-col>
        <vs-col
          class="mb-3"
          vs-w="3"
          vs-sm="3"
          vs-xs="6"
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
        >

          <vx-badge
            pack="fas"
            icon="key"
            color="light-warning"
            icon-color-bg="warning"
            icon-color="null"
          >
            {{ $t('Change Password') }}
          </vx-badge>
        </vs-col>
        <vs-col
          class="mb-3"
          vs-w="3"
          vs-sm="3"
          vs-xs="6"
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
        >
          <vx-badge
            pack="fas"
            icon="ellipsis-v"
            color="light-primary"
            icon-color-bg="primary"
            icon-color="null"
          >
            {{ $t('More Options') }}
          </vx-badge>
        </vs-col>
      </vs-row>
    </b-card>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  name: "ExplanationsComponent",
}

</script>

<style scoped>

</style>
