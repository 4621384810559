<template>
  <div>
    <vs-button
      color="success"
      type="gradient"
      icon="add"
      @click="status = true"
    >
      {{ $t('NewUser') }}
    </vs-button>
    <b-sidebar
      id="sidebar-right"
      v-model="status"
      bg-variant="white"
      right
      backdrop
      shadow
      @hidden="close"
    >
      <b-container>
        <template>
          <b-row
            class="img-container w-64 mx-auto flex items-center justify-center"
            align-h="center"
          >
            <b-col
              cols="6"
            >
              <vx-upload
                v-model="UserData.avatar_id"
                class="mt-2"
              />
            </b-col>
            <b-col
              v-if="!UserData.avatar_id"
              class="text-center"
              cols="12"
            >
              {{ $t('Upload Your Avatar') }}
            </b-col>
            <b-col cols="12">

              <vx-input
                v-model="UserData.first_name"
                class="mt-2"
                :label="$t('First Name')"
                :errors="errors.first_name"
              />

              <vx-input
                v-model="UserData.last_name"
                class="mt-2"
                :label="$t('Last Name')"
                :errors="errors.last_name"
              />

              <vx-input
                v-model="UserData.email"
                class="mt-2"
                :label="$t('Email')"
                :errors="errors.email"
              />

              <vx-input
                v-model="UserData.phone"
                class="mt-2"
                type="number"
                :label="$t('Phone')"
                :errors="errors.phone"
              />
              <vs-textarea
                v-model="UserData.address"
                label="Address"
              />
              <vs-textarea
                v-model="UserData.ex_address"
                label="Second Address"
              />
            </b-col>
            <b-col cols="12">
              <vs-button
                icon-after
                color="success"
                type="gradient"
                class="mb-2 max-w"
                @click="CreateNewUser()"
              >{{ $t('Create User') }}</vs-button>
            </b-col>
            <b-col cols="12">
              <vs-button
                icon-after
                color="danger"
                type="gradient"
                class="mb-2 max-w"
                @click="close()"
              >
                {{ $t('Cancel') }}
              </vs-button>
            </b-col>
          </b-row>
        </template>
      </b-container>
    </b-sidebar>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  name: "NewUserComponent",

  data: () => ({
    UserData: {
      first_name: '',
      last_name: '',
      email: '',
      phone: '',
      avatar_id: null,
      ex_address: '',
      address: '',
    },
    errors:[],
    status: false,
    StatusOption: ['Active', 'Blocked', 'Deactivated'],
    LanguageOption: ['Arabic', 'English', 'Turkish'],
  }),
  computed:{
  },
methods:{
  close()
  {
    this.UserData.first_name=''
    this.UserData.last_name=''
    this.UserData.email=''
    this.UserData.mobile=''
    this.UserData.tc=''
    this.UserData.avatar_id=null
    this.UserData.status=''
    this.UserData.languages=''
    this.status=false
    this.errors=[]

  },
  CreateNewUser(){
    this.errors=[]
    this.$store.dispatch('user/CreateUser',this.UserData).then(() => {
      this.close()
    }).catch((error) => {
      if(error.response.data.errors)
        this.errors = error.response.data.errors
    })
  }
},
  created() {
    // this.$store.dispatch('role/getRoles')
  }
}
</script>

